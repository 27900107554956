<template>
  <div id="section4">
    <div class="fondoGrisClaro" id="palbin">
      <b-container fluid>
        <b-container>
          <br>
          <h1>
            {{$t("title1")}}
          </h1>
          <h2>
            {{$t("title2")}}
          </h2>
          <br>
          <b-row>
            <b-col md="6" sm="12">
              <b-img :src="img"/>
            </b-col>
            <b-col md="6" sm="12">
              <div class="pb-4 mb-4">                
                <h3>
                  {{$t("title3")}}
                </h3>
                <ol>
                  <li v-for="(item, index) in list3" :key="index">
                    {{item}}
                  </li>
                </ol>
              </div>
              <div class="mb-2">  
                <h3>
                  {{$t("title4")}}
                </h3>
                <ul>
                  <li v-for="(item, index) in list4" :key="index">
                    {{item}}
                  </li>
                </ul>
              </div>
              <br>
            </b-col>
          </b-row>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    
  },
  "es":{
    "title1": "Integración de formulario INFRAME – Hosted Payments."
    , "title2": "Una experiencia de usuario diferente: en lugar de redirigir al cliente a las páginas hosteadas de Snap*, permanece en tu página web para hacer el pago."
    , "title3": "Cómo funciona:"
    , "list3": [
      "El cliente añade al carrito de la compra los artículos que le interesan."
      , "A la hora de pagar, ingresa los datos de su tarjeta en una página de pago seguro en tu web."
      , "Recopilamos los detalles del pago, lo autorizamos y te enviamos la confirmación cuando la transacción se ha completado."
      , "Almacenas en tu web todos los detalles de pago y de transacción."
    ]
    , "title4": "Beneficios:"
    , "list4": [
      "Personaliza completamente la página de pagos alojada en tu web."
      , "Una experiencia de pago más fluida, reuniendo todos los detalles de pago en una sola página."
      , "No se almacenan en tu web los datos de tarjeta del cliente."
      , "Tu negocio se favorece de los requerimientos reducidos de PCI-DSS."
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      img: require("@/assets/images/pay/integracion_03.png")
    }
  },
  methods: {
    
  },
  computed: {
    list3() {
      return this.$t("list3");
    },
    list4() {
      return this.$t("list4");
    }
  }
};
</script>
