<template>
  <div id="section2">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container >
          <br />
          <h1  class="text-left">
            {{$t("title")}}
          </h1>
          <br />
          <b-row >
            <b-col md="4" sm="12" v-for="(item, index) in btnNames" 
              class="text-center pb-5" :key="index">
              <div>
                <b-img :src="btns[index].img"/>
              </div>
              <br>
              <a v-scroll-to="btns[index].url" class="btn buttonAzul">
                {{item.b1}}
                <br>
                {{item.b2}}
              </a>
            </b-col>
          </b-row>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    
  },
  "es":{
    "title": "Integración de servicios de pago adaptados a tus necesidades técnicas y de negocio."
    , "btnNames": [
      {
        "b1":"INTEGRACIÓN DE"
        ,"b2":"FORMULARIO"
      }, 
      {
        "b1":"INTEGRACIÓN DE"
        ,"b2":"FORMULARIO INFRAME"
      }, 
      {
        "b1":"INTEGRACIÓN DE"
        ,"b2":"SERVIDOR O DIRECTA"
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      btns: [
        {
          img: require("@/assets/images/pay/icons_desarrolladores_01.png")
          , url: "#section3"
        },
        {
          img: require("@/assets/images/pay/icons_desarrolladores_02.png")
          , url: "#section4"
        },
        {
          img: require("@/assets/images/pay/icons_desarrolladores_03.png")
          , url: "#section5"
        }
      ]
    };
  },
  computed: {
    btnNames() {
      return this.$t("btnNames");
    }
  }
};
</script>
