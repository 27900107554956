<template>
  <div id="section5">
    <div class="fondoWhite" id="palbin">
      <b-container fluid>
        <b-container>
          <br>
          <h1>
            {{$t("title1")}}
          </h1>
          <h2>
            {{$t("title2")}}
          </h2>
          <br>
          <b-row>
            <b-col md="6" sm="12">
              <b-img :src="img"/>
            </b-col>
            <b-col md="6" sm="12">
              <div class="pb-5 mb-5">                
                <h3>
                  {{$t("title3")}}
                </h3>
                <ol>
                  <li v-for="(item, index) in list3" :key="index">
                    {{item}}
                  </li>
                </ol>
              </div>
              <br>
              <div>  
                <h3>
                  {{$t("title4")}}
                </h3>
                <ul>
                  <li v-for="(item, index) in list4" :key="index">
                    {{item}}
                  </li>
                </ul>
              </div>
              <div>
                {{$t("text")}}
              </div>
              <br>
            </b-col>
          </b-row>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    
  },
  "es":{
    "title1": "Integración de servidor o directa – Commerce Web Services*."
    , "title2": "Permite diseñar desde cero la experiencia y gestionar tus propias páginas de pago, ofreciendo una experiencia personalizada del proceso de pago."
    , "title3": "Cómo funciona:"
    , "list3": [
      "El cliente añade al carrito de la compra los artículos que le interesan."
      , "A la hora de pagar, ingresa los datos de su tarjeta en una página de pago seguro en tu web."
      , "Tu servidor almacena toda la información y envía los detalles de pago a Snap* para autorizarlo."
      , "Una vez completado el proceso, Snap* te envía la confirmación."
      , "Los datos de pago, cliente y transacción se almacenan en tu servidor."
    ]
    , "title4": "Beneficios:"
    , "list4": [
      "Control completo sobre la experiencia de check out."
      , "Almacenas todos los detalles de pago y de transacción."
    ]
    , "text": "*Requiere de licencia de PCI-DSS para operar."
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      img: require("@/assets/images/pay/integracion_01.png")
    }
  },
  methods: {
    
  },
  computed: {
    list3() {
      return this.$t("list3");
    },
    list4() {
      return this.$t("list4");
    }
  }
};
</script>
